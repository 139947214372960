<template>
  <div>
    <div class="login-out" @click="handleLoginOut"> 退出登录 </div>
    <PCPage v-if="$store.getters.model == 'pc'"></PCPage>
    <MobilePage v-if="$store.getters.model == 'mobile'"></MobilePage>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import PCPage from "@/components/pcPage";
import MobilePage from "@/components/mobilePage";
export default {
  components: {
    PCPage,
    MobilePage,
  },
  mounted () {
    console.log(this.$store.getters, '1')
  },
  data() {
    return {
      model: ''
    };
  },
  methods: {
    handleLoginOut () {
      
      this.$confirm('确定退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Cookies.remove('Authorization');
        this.$router.push({ name: 'Login' })
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login-out {
  position: absolute;
  z-index: 999;
  left: 0px;
  top: 60vh;
  width: 60px;
  height: 60px;
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  line-height: 60px;
  color: #3f85ff;
  font-size: 12px;
  cursor: pointer;
}
.login-out:hover {
  color: #ffffff;
  background: #3f85ff;
}
</style>
