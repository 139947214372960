import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    model: ''
  },
  getters: {
    model: state => state.model
  },
  mutations: {
    SAVE_MODEL (state, model) {
      state.model = model
    }
  },
  actions: {
  },
  modules: {
  },
});
