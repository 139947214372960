import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
import api from '@/request/index.js'
import * as tools from 'toolsjs_zsh'
import scroll from 'vue-seamless-scroll'
import { Tab, Tabs } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入用户验证
import './verify.js'

Vue.use(Tab);
Vue.use(Tabs);

Vue.prototype.$T = tools 
Vue.use(scroll)

Vue.config.productionTip = false;

Vue.prototype.$api = api

Vue.use(Antd);

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
