<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 设备尺寸集合
      sizeScope: [
        [0, 575, "mobile"],
        [576, 767, "pc"],
        [768, 991, "pc"],
        [992, 1199, "pc"],
        [1200, 1599, "pc"],
        [1600, 99999, "pc"],
      ],
      // 设备类型
      model: "",
    };
  },
  mounted() {
    this.init(); // 初始化判断设备
    this.getEquipmentSize(); // 尺寸改变的时候判断设备
  },
  methods: {
    // 初始化
    init() {
      const clientWidth = document.documentElement.clientWidth; // 设备宽度
      this.sizeScope.forEach((item, index) => {
        if (clientWidth >= item[0] && clientWidth <= item[1]) {
          this.model = item[2]; // 得到设备类型
        }
        this.$store.commit("SAVE_MODEL", this.model);
      });
    },
    // 得到设备尺寸
    getEquipmentSize() {
      window.onresize = () => {
        this.init();
      };
    },
  },
};
</script>

<style lang="less">
html {
  touch-action: none;
  touch-action: pan-y;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  background-color: #F5F5F5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bdbdbd;
}
/*滑块效果*/
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.4);
}
// .group {
//   font-size: 1rem;
// }
</style>
