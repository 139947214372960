import Vue from 'vue'
import router from './router'
import Cookies from 'js-cookie'
// import { userList } from '@/utils/user.js'
// let Base64 = require('js-base64').Base64
const allowList = ['Login'] // 不需要登录可以进入的页面
const loginRoutePath = '/login'
const AUTHORIZATION = 'Authorization'

router.beforeEach((to, from, next) => {

  /* 有 token */
  if (Cookies.get(AUTHORIZATION)) {
    if (to.path === loginRoutePath) {
      Cookies.remove('Authorization');
      next()
    } else {
      Vue.prototype.$api.user.userInfo().then((res) => {
        const isHasKey = Object.keys(res)
        if (isHasKey && isHasKey.length) {
          next()
        } else {
          next({ path: '/login' })
        }
      }).catch(() => {
        next({ path: '/login' })
      })
      // const md5_token = Base64.decode(Cookies.get(AUTHORIZATION));
      // const isHasToken = userList.filter(user => user.Authorization === md5_token)

      // if (isHasToken && isHasToken.length) next()
      // else next({ path: '/login' })
      
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath })
    }
  }
})


