import { requestGet } from '../../axios'

const dir = '/achievement/achievement/'

export default {
  // 获取部门排行
  countByDept: (params) => requestGet(dir + 'count-by-dept', params),
  // 获取商品排行
  countByProducts: (params) => requestGet(dir + 'count-by-products', params),
  // 获取最新订单
  getRecentlyOrder: (params) => requestGet(dir + 'get-recently-order', params),
  // 获取用户汇总信息
  countByPerson: (params) => requestGet(dir + 'count-by-person', params),
  // 获取店铺出单排名
  countBySeller: (params) => requestGet(dir + 'count-by-seller', params),
  // 获取店铺业绩
  getSellerInfo: (params) => requestGet(dir + 'get-seller-info', params),
  // 获取轮播图片
  readFileList: (params) => requestGet(dir + 'read-file-list', params),
  // 总数统计
  totals: (params) => requestGet(dir + 'totals', params)
}