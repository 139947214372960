import axios from 'axios'
import { VueAxios } from './axios'
import Cookies from 'js-cookie'
const request = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 1000 * 10
})

// 异常拦截器
const errorHandler = error => {
  if (error.response) {
    const data = error.response.data
    this.$notification['error']({
      message: '请求异常',
      description: data.msg || '请求异常'
    });
  }
  return Promise.reject(error)
}

// 请求拦截
request.interceptors.request.use(config => {
  const token = Cookies.get('Authorization')
  if (token) {
    config.headers['Access-Token'] = token
  }
  return config
})

// 响应拦截
request.interceptors.response.use(response => {
  const result = response.data
  if (result.data) {
    return result.data
  } else {
    const str = result.replace(/^(\s|\()+|(\s|\))+$/g, '')
    return JSON.parse(str)
  }
  
}, errorHandler)

// GET请求
export const requestGet = (url, params) => request.get(url, { params })
// DELETE请求
export const requestDelete = (url, params) => request.delete(url, { params })
// POST请求
export const requestPost = (url, params, config = {}) => request.post(url, params, config)
// PUT请求
export const requestPut = (url, params) => request.put(url, params)
// 导出Excel
export const exportExcel = (url, params) => request.get(url, { params, responseType: 'blob', timeout: 0 })
// 导入Excel
export const importExcel = (url, params) => request.post(url, params, { timeout: 0 })

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}