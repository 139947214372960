// 语法: require.context(directory, useSubdirectories = false, regExp = /^.//);
// directory {String} -读取文件的路径
// useSubdirectories {Boolean} -是否遍历文件的子目录
// regExp {RegExp} -匹配文件的正则
const files = require.context('./api/', true, /\.js$/)
const modules = {}
files.keys().forEach(key => {
  // 模块名称提取
  const moduleKey = key.replace(/(.*\/)*([^.]+).*/ig, '$2')
  if (modules[moduleKey]) {
    console.warn('API模块命名重复', key)
  } else {
    modules[moduleKey] = files(key).default
  }
  // modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
export default modules
